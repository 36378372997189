import React, { useEffect, useState, useContext } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import MainNavigation from "../../shared/menu/MainNavigation";
import { Link } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import moment from "moment";
import axios from "axios";
import {
  Layout,
  Table,
  Tag,
  Input,
  DatePicker,
  Button,
  Select,
  Alert,
  Drawer,
  Typography,
  Spin,
  Card,
  Tooltip,
} from "antd";
import chess from "../../../img/chess.png";

const { Content, Sider } = Layout;
const { Search } = Input;
const { Option } = Select;
const { Title } = Typography;

function Participants() {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const { id: userId } = useParams();
  const [loading, setLoading] = useState(false);
  // const [drawerVisible, setDrawerVisible] = useState(false);
  const [tournaments, setTournaments] = useState([]);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [registrations, setRegistrations] = useState([]);

  useEffect(() => {
    const fetchTournaments = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}tournaments/${auth.userId}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        console.log(response.data);
        setTournaments(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching tournaments:", error);
        setLoading(false);
      }
    };

    fetchTournaments();
  }, [auth.userId, auth.token]);

  const handleViewParticipants = (tournamentId, tournamentName) => {
    history.push(`/participants/${userId}/${tournamentId}`, {
      tournamentName: tournamentName,
    });
  };

  // const handleCloseTable = () => {
  //   setSelectedTournament(null);
  //   setRegistrations([]);
  // };

  // const columns = [
  //   {
  //     title: "Name",
  //     dataIndex: "name",
  //     key: "name",
  //   },
  //   {
  //     title: "Date of Birth",
  //     dataIndex: "dob",
  //     key: "dob",
  //     render: (dob) => moment(dob).format("DD-MM-YYYY"),
  //   },
  //   {
  //     title: "Category",
  //     dataIndex: "category",
  //     key: "category",
  //   },
  //   {
  //     title: "FIDE ID",
  //     dataIndex: "fideId",
  //     key: "fideId",
  //   },
  //   {
  //     title: "FIDE Rating",
  //     dataIndex: "fideRating",
  //     key: "fideRating",
  //   },
  //   {
  //     title: "Contact Number",
  //     dataIndex: "contactNumber",
  //     key: "contactNumber",
  //   },
  //   {
  //     title: "Email",
  //     dataIndex: "email",
  //     key: "email",
  //   },
  // ];

  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
          backgroundColor: "white",
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div style={{ padding: "10px 10px 0", minHeight: "50vh" }}>
            <Title
              level={3}
              style={{
                marginLeft: 40,
                marginTop: 10,
                marginBottom: 20,
                fontWeight: "bold",
                color: "gray",
              }}
            >
              {" "}
              Registration for your Tournaments
            </Title>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                marginLeft: 10,
                marginBlock: 10,
              }}
            >
              {tournaments.map((tournament) => (
                <Card
                  key={tournament._id}
                  className="mt-2 mr-2 "
                  title={
                    <Tooltip
                      title={
                        <span style={{ fontSize: 14 }}>{tournament.name}</span>
                      }
                    >
                      <span style={{ fontSize: 18, fontWeight: "bold" }}>
                        {tournament.name}
                      </span>
                    </Tooltip>
                  }
                  style={{
                    width: 500,
                    height: 300,
                    cursor: "pointer",
                    marginInline: 30,
                    backgroundColor: "white",
                    transition: "box-shadow 0.3s",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                  // onClick={() =>
                  //   handleViewParticipants(tournament._id, tournament.name)
                  // }
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.boxShadow =
                      "0 8px 16px rgba(0, 0, 0, 0.2)")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.boxShadow =
                      "0 4px 8px rgba(0, 0, 0, 0.1)")
                  }
                >
                  <p style={{ color: "gray", fontSize: 16, marginTop: 2 }}>
                    Start Date - End Date
                    <p
                      style={{
                        color: "black",
                        fontSize: 16,
                        marginTop: 2,
                        fontWeight: "bold",
                      }}
                    >
                      {moment(tournament.startDate).format("DD-MM-YYYY")} -{" "}
                      {moment(tournament.endDate).format("DD-MM-YYYY")}
                    </p>
                  </p>
                  {/* <p>
                  End Date: {moment(tournament.endDate).format("DD-MM-YYYY")}
                </p> */}
                  <p style={{ fontSize: 16, color: "gray" }}>
                    Status
                    <p
                      style={{
                        color:
                          tournament.status === "live" ? "green" : "orange",
                        fontSize: 18,
                        fontWeight: "bold",
                        marginLeft: 5,
                        marginTop: 2,
                      }}
                    >
                      {tournament.status}
                    </p>
                  </p>
                  <p
                    style={{
                      color: "gray",
                      fontSize: 16,
                    }}
                  >
                    Number of Registrations
                    <p
                      style={{
                        color: "black",
                        fontSize: 18,
                        fontWeight: "bold",
                        marginTop: 2,
                      }}
                    >
                      {tournament.registrations.length}
                    </p>
                  </p>
                  <div
                    style={{ position: "absolute", bottom: 200, right: 120 }}
                  >
                    <img
                      src={require("../../../img/chess.png")} // Replace 'tournament.image' with the actual image source
                      alt="Tournament"
                      style={{
                        position: "absolute",
                        width: 100,
                        height: 100,
                      }}
                    />
                  </div>
                  <div style={{ position: "absolute", right: 10, bottom: 10 }}>
                    <Button
                      type="primary"
                      onClick={() =>
                        handleViewParticipants(tournament._id, tournament.name)
                      }
                    >
                      View Participants
                    </Button>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Participants;
