import React, { useEffect, useState, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import axios from "axios";
import {
  Spin,
  Typography,
  Button,
  Table,
  Layout,
  Select,
  Input,
  Drawer,
  Card,
  Pagination,
} from "antd";
import MainNavigation from "../../shared/menu/MainNavigation";
import moment from "moment";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";

const { Search } = Input;
const { Meta } = Card;
const { Option } = Select;
const { Title } = Typography;

function ParticipantsPage() {
  const auth = useContext(AuthContext);
  const { userId, tournamentId } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const tournamentName = location.state ? location.state.tournamentName : "";
  const [registrations, setRegistrations] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedRegistration, setSelectedRegistration] = useState(null);

  useEffect(() => {
    const fetchRegistrations = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}registrations/${userId}/${tournamentId}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        console.log(response.data);
        setRegistrations(response.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching registrations:", error);
        setLoading(false);
      }
    };

    fetchRegistrations();
  }, [auth.token, userId, tournamentId]);

  // const handlePageChange = (page, pageSize) => {
  //   setPagination({ current: page, pageSize });
  // };

  const handleViewDetails = (registration) => {
    setSelectedRegistration(registration);
    setDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const renderRegistrationCard = (registration) => {
    return (
      <Card
        key={registration._id}
        className="registration-card"
        hoverable
        onClick={() => handleViewDetails(registration)}
        style={{ width: 300, margin: "0 25px" }}
      >
        <Meta
          title={registration.name}
          description={
            <>
              <p style={{ fontWeight: "bold" }}>
                Date of Birth: {moment(registration.dob).format("DD-MM-YYYY")}
              </p>
              <p style={{ fontWeight: "bold" }}>
                Category: {registration.category}
              </p>
              <p style={{ fontWeight: "bold" }}>
                FIDE ID: {registration.fideId}
              </p>
              <p style={{ fontWeight: "bold" }}>
                FIDE Rating: {registration.fideRating}
              </p>
              <p style={{ fontWeight: "bold" }}>
                Contact Number: {registration.contactNumber}
              </p>
              <p style={{ fontWeight: "bold" }}>Email: {registration.email}</p>
            </>
          }
        />
      </Card>
    );
  };

  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
          backgroundColor: "white",
        }}
      >
        <Spin spinning={loading}>
          <div style={{ padding: 24 }}>
            <Title level={2}>Participants for {tournamentName}</Title>
            {registrations.length > 0 ? (
              <div
                className="registration-cards"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {registrations.map((registration) =>
                  renderRegistrationCard(registration)
                )}
              </div>
            ) : (
              <p>No Registrations yet for this Tournament !</p>
            )}
          </div>
        </Spin>
      </Layout>
      <Drawer
        title="Registration Details"
        placement="right"
        closable={true}
        onClose={handleCloseDrawer}
        open={drawerVisible}
        width={400}
        className="registration-drawer"
      >
        {selectedRegistration && (
          <div className="registration-details">
            <h2>{selectedRegistration.name}</h2>
            <p>
              Date of Birth:{" "}
              {moment(selectedRegistration.dob).format("DD-MM-YYYY")}
            </p>
            <p>Category: {selectedRegistration.category}</p>
            <p>FIDE ID: {selectedRegistration.fideId}</p>
            <p>FIDE Rating: {selectedRegistration.fideRating}</p>
            <p>Contact Number: {selectedRegistration.contactNumber}</p>
            <p>Email: {selectedRegistration.email}</p>
          </div>
        )}
      </Drawer>
    </Layout>
  );
}

export default ParticipantsPage;
