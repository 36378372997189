import { useState, useCallback, useEffect } from "react";

let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userId, setUserId] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  console.log("isAdmin at login:", isAdmin);

  const login = useCallback(
    (uid, token, expirationDate, isAdminParam) => {
      setToken(token);
      setUserId(uid);
      setIsAdmin(isAdminParam);
      console.log("isAdmin in useAuth:", isAdminParam);
      const calculatedExpirationDate =
        expirationDate instanceof Date
          ? expirationDate
          : new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365);
      setTokenExpirationDate(calculatedExpirationDate);
      localStorage.setItem(
        "userData",
        JSON.stringify({
          userId: uid,
          token: token,
          expiration: calculatedExpirationDate.toISOString(),
          isAdmin: isAdminParam,
        })
      );
    },
    [isAdmin]
  );

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    setIsAdmin(false);
    localStorage.removeItem("userData");
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const storedData = JSON.parse(localStorage.getItem("userData"));
    console.log("Stored Data:", storedData);
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.userId,
        storedData.token,
        new Date(storedData.expiration),
        storedData.isAdmin
      );
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  return { token, login, logout, userId, isLoading, isAdmin };
};
