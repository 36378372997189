import React, { createContext, useContext, useState } from "react";

const SuccessContext = createContext();

export const useSuccess = () => {
  return useContext(SuccessContext);
};

export const SuccessProvider = ({ children }) => {
  const [successMessage, setSuccessMessage] = useState("");

  const setSuccess = (message) => {
    setSuccessMessage(message);
  };

  const clearSuccess = () => {
    setSuccessMessage("");
  };

  return (
    <SuccessContext.Provider
      value={{
        successMessage,
        setSuccess,
        clearSuccess,
      }}
    >
      {children}
    </SuccessContext.Provider>
  );
};
