import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import {
  Form,
  Input,
  Button,
  Layout,
  Spin,
  Select,
  Upload,
  DatePicker,
  TimePicker,
  Radio,
} from "antd";
import indianStates from "../../../constants";
import {
  UploadOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { useSuccess } from "../../shared/context/success-context";
import MainNavigation from "../../shared/menu/MainNavigation";
import { AuthContext } from "../../shared/context/auth-context";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet";
import moment from "moment";

const { Content, Sider } = Layout;
const { TextArea } = Input;
const { Option } = Select;

function EditTournament() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [ShowSuccessMessage, setShowSuccessMessage] = useState();
  const { isLoading, sendRequest } = useHttpClient();
  const [isFormComplete, setIsFormComplete] = useState(false);
  const auth = useContext(AuthContext);
  const userId = useParams().id;
  const history = useHistory();
  const [nameChange, setNameChange] = useState();
  const [organization, setOrganization] = useState();
  const [totalPrize, setTotalPrize] = useState();
  const [address, setAddress] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  // const [timeControl, setTimeControl] = useState();
  const [lastDate, setLastDate] = useState("");
  // const [tournamentRules, setTournamentRules] = useState();
  // const [importantPoints, setImportantPoints] = useState();
  const [startDate, setStartDate] = useState();
  // const [dateRange, setDateRange] = useState([null, null]);

  const [endDate, setEndDate] = useState();
  const [entryFees, setEntryFees] = useState([{ category: "", fee: "" }]);
  const [contactPerson, setContactPerson] = useState([
    { name: "", position: "", number: "" },
  ]);
  // const [bannerImage, setBannerImage] = useState(null);
  const [brochure, setBrochure] = useState(null);
  const [brochureUrl, setBrochureUrl] = useState();
  // const [bannerImagePreview, setBannerImagePreview] = useState(null);
  const [brochurePreview, setBrochurePreview] = useState(null);
  const [imageSizeValid, setImageSizeValid] = useState(true);
  // const [prizes, setPrizes] = useState([
  //   { category: "", prizes: [{ position: "", prize: "" }] },
  // ]);
  // const [schedule, setSchedule] = useState([
  //   {
  //     text: "",
  //     date: null,
  //     time: null,
  //   },
  // ]);

  const acceptedFileTypes = {
    "image/png": ".png",
    "image/jpeg": ".jpeg",
    "application/pdf": ".pdf",
  };

  const MAX_FILE_SIZE_MB = 5;

  const handleCategoryChange = (e, index) => {
    const updatedEntryFees = [...entryFees];
    updatedEntryFees[index].category = e.target.value;
    setEntryFees(updatedEntryFees);
  };

  const handleFeeChange = (e, index) => {
    const updatedEntryFees = [...entryFees];
    updatedEntryFees[index].fee = e.target.value;
    setEntryFees(updatedEntryFees);
  };

  const addEntryField = () => {
    setEntryFees([...entryFees, { category: "", fee: "" }]);
  };

  const handleRemoveEntry = (index) => {
    const updatedEntryFees = [...entryFees];
    updatedEntryFees.splice(index, 1);
    setEntryFees(updatedEntryFees);
  };

  const handleContactChange = (e, index) => {
    const updatedContact = [...contactPerson];
    updatedContact[index].name = e.target.value;
    setContactPerson(updatedContact);
  };

  const handleContactPositionChange = (e, index) => {
    const updatedPosition = [...contactPerson];
    updatedPosition[index].position = e.target.value;
    setContactPerson(updatedPosition);
  };

  const handleContactNumberChange = (e, index) => {
    const updatedNumber = [...contactPerson];
    updatedNumber[index].number = e.target.value;
    setContactPerson(updatedNumber);
  };

  const addContactField = () => {
    setContactPerson([
      ...contactPerson,
      { name: "", position: "", number: "" },
    ]);
  };

  const handleContactRemoveEntry = (index) => {
    const updatedContact = [...contactPerson];
    updatedContact.splice(index, 1);
    setContactPerson(updatedContact);
  };

  // const handleBannerImageUpload = (file) => {
  //   if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
  //     setImageSizeValid(false);
  //     ToastContainer.error(
  //       `File size exceeds the limit of ${MAX_FILE_SIZE_MB}MB`,
  //       {
  //         position: toast.POSITION.TOP_CENTER,
  //         autoClose: 3000,
  //         hideProgressBar: true,
  //       }
  //     );
  //     return false;
  //   }

  //   setImageSizeValid(true);
  //   setBannerImage(file);
  //   return false; // Prevent default upload behavior
  // };

  const handleBrochureUpload = (file) => {
    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      setImageSizeValid(false);
      ToastContainer.error(
        `File size exceeds the limit of ${MAX_FILE_SIZE_MB}MB`,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: true,
        }
      );
      return false;
    }
    setImageSizeValid(true);
    setBrochure(file);
    return false; // Prevent default upload behavior
  };

  useEffect(() => {
    if (brochure) {
      try {
        setBrochurePreview(URL.createObjectURL(brochure));
      } catch (error) {
        console.error("Failed to create object URL:", error);
      }
    } else {
      setBrochurePreview(null);
    }
  }, [brochure]);

  useEffect(() => {
    const fetchTournament = async () => {
      try {
        setLoading(true);

        const responseData = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}tournaments/details/${userId}`,
          { headers: { Authorization: `Bearer ${auth.token}` } }
        );
        setLoading(false);

        const startDate = moment(responseData.data.tournament.startDate);
        const endDate = moment(responseData.data.tournament.endDate);
        console.log(responseData.data);

        setTotalPrize(responseData.data.tournament.totalPrize || "");
        setNameChange(responseData.data.tournament.name || "");
        setOrganization(responseData.data.tournament.organization || "");
        setEntryFees(
          responseData.data.tournament.entryFees || [{ category: "", fee: "" }]
        );
        setContactPerson(
          responseData.data.tournament.contactPerson || [
            { name: "", position: "", number: "" },
          ]
        );
        setStartDate(startDate);

        setEndDate(endDate);

        setAddress(responseData.data.tournament.address || "");
        setCity(responseData.data.tournament.city || "");
        setState(responseData.data.tournament.state || "");
        setLastDate(responseData.data.tournament.lastDate || "");
        setBrochureUrl(responseData.data.tournament.brochureURL);
        setBrochure(responseData.data.tournament.brochureImage || null);
        setBrochurePreview(responseData.data.tournament.brochureUrl || null);
      } catch (err) {
        console.log(err);
      }
    };
    fetchTournament();
  }, [auth.token, userId]);

  console.log(startDate, endDate, lastDate);

  const handleSubmit = async (e) => {
    setLoading(true);

    const entryFeesArray = entryFees.map((entry) => ({
      category: entry.category,
      fee: entry.fee,
    }));

    const contactPersonArray = contactPerson.map((entry) => ({
      name: entry.name,
      position: entry.position,
      number: entry.number,
    }));

    try {
      const formData = new FormData();
      formData.append("name", nameChange);
      formData.append("organization", organization);
      formData.append("totalPrize", totalPrize);
      formData.append("address", address);
      formData.append("city", city);
      // formData.append("timeControl", timeControl);
      formData.append("lastDate", lastDate);
      formData.append("state", state);
      formData.append("startDate", startDate);
      formData.append("endDate", endDate);

      formData.append("entryFees", JSON.stringify(entryFeesArray));
      formData.append("contactPerson", JSON.stringify(contactPersonArray));

      formData.append("creator", auth.userId);

      formData.append("brochure", brochure); // Append the brochure file
      formData.append("brochureUrl", brochureUrl); // Append the brochure file
      console.log("Form Data:", formData);

      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}tournaments/update/${userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
          },
        }
      );

      if (brochure && formData.brochureImage) {
        // Delete the old brochure image
        await axios.delete(
          `${process.env.REACT_APP_BACKEND_URL}tournaments/delete-image/${formData.brochureImage}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
      }

      setLoading(false);

      // Reset the form fields
      setNameChange("");
      setAddress("");
      setCity("");
      setState("");
      setStartDate(null);
      setEndDate(null);
      setEntryFees([{ category: "", fee: "" }]);
      // setBannerImage(null);
      setBrochure(null);
      setBrochureUrl("");
      // setBannerImagePreview(null);
      setBrochurePreview(null);
      setContactPerson([{ name: "", position: "", number: "" }]);
      // setTournamentRules("");
      // setImportantPoints("");
      // setSchedule([{ text: "", date: null, time: null }]);
      // setPrizes([]);
      setOrganization("");
      setTotalPrize("");
      // setTimeControl("");
      setLastDate("");

      toast.success("Tournament updated successfully", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
      });

      // console.log(response.data); // Handle the response data as needed
      window.location.href = "/";
    } catch (error) {
      console.log(error);
      toast.error("Failed to update tournament", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

  console.log("StartDate:", startDate);
  console.log("End Date:", endDate);

  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <ToastContainer />

          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            {loading ? <Spin /> : !loading}

            <div>
              <div style={{ marginTop: "20px" }}>
                <Form layout="vertical" onSubmit={handleSubmit}>
                  <div>
                    <div style={{ width: "100%" }}>
                      <div
                        className="main-heading"
                        style={{ marginBottom: "20px" }}
                      >
                        <div>
                          <h2>Edit: {nameChange}</h2>
                        </div>
                      </div>
                      <Form.Item label="Tournament Name">
                        <Input
                          name="name"
                          type="text"
                          value={nameChange}
                          size="large"
                          onChange={(e) => setNameChange(e.target.value)}
                          placeholder="Enter tournament name"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        />
                      </Form.Item>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "20px",
                        }}
                      >
                        <Form.Item
                          label="Organized by"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input
                            name="organization"
                            type="text"
                            value={organization}
                            size="large"
                            onChange={(e) => setOrganization(e.target.value)}
                            placeholder="Enter Organization name"
                          />
                        </Form.Item>

                        <Form.Item
                          label="Total Prize "
                          style={{ width: "100%" }}
                        >
                          <Input
                            name="totalPrize"
                            type="text"
                            placeholder="Enter the total prize fund"
                            value={totalPrize}
                            onChange={(e) => setTotalPrize(e.target.value)}
                            size="large"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          />
                        </Form.Item>
                      </div>
                      {/* Editing the Event Address  */}
                      <Form.Item label="Event Address">
                        <Input
                          name="address"
                          placeholder="Enter Event Address"
                          type="text"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          size="large"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        />
                      </Form.Item>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "20px",
                        }}
                      >
                        <Form.Item
                          label="Enter Event City"
                          style={{ width: "100%" }}
                        >
                          <Input
                            name="city"
                            placeholder="Enter Event City"
                            value={city}
                            type="text"
                            size="large"
                            onChange={(e) => setCity(e.target.value)}
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          />
                        </Form.Item>

                        {/* Editing the state */}

                        <Form.Item
                          label="Enter Event State"
                          style={{ width: "100%" }}
                          name="state"
                          rules={[
                            {
                              required: true,
                              message: "Please select your event state",
                            },
                          ]}
                        >
                          <Select
                            value={state}
                            onChange={(value) => setState(value)}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {indianStates.map((state) => (
                              <Option key={state} value={state}>
                                {state}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                      {/* Editing the event dates  */}

                      <Form.Item
                        label="Select Event Dates"
                        name="select your dates"
                        style={{ width: "100%" }}
                        rules={[
                          {
                            type: "array",
                            required: true,
                            message: "Please select the date range!",
                          },
                        ]}
                      >
                        <DatePicker.RangePicker
                          style={{ width: "100%" }}
                          value={[
                            moment("2024-04-04", "YYYY-MM-DD"), // Start date
                            moment("2024-04-12", "YYYY-MM-DD"), // End date
                          ]}
                          onChange={(dates) => {
                            setStartDate(dates[0]);
                            setEndDate(dates[1]);
                          }}
                          format="DD-MM-YYYY"
                          size="large"
                        />
                      </Form.Item>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "20px",
                        }}
                      >
                        <Form.Item
                          label="Last Date for Registration"
                          style={{ width: "100%" }}
                          name="last date"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please input last date for registration",
                            },
                          ]}
                        >
                          <Input
                            placeholder="24th April, 2023"
                            type="text"
                            size="large"
                            value={lastDate}
                            onChange={(e) => setLastDate(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                      <div
                        style={{ backgroundColor: "#f9f9f9", padding: "20px" }}
                      >
                        <Form.Item label="Entry Fee Structure">
                          {entryFees.map((entry, index) => (
                            <>
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "flex-end",
                                  gap: "10px",
                                }}
                              >
                                <Form.Item
                                  label="Category"
                                  style={{ marginBottom: 0, width: "100%" }}
                                >
                                  <Input
                                    value={entry.category}
                                    onChange={(e) =>
                                      handleCategoryChange(e, index, "category")
                                    }
                                    placeholder="Category"
                                  />
                                </Form.Item>
                                <Form.Item
                                  label="Entry Fee"
                                  style={{ marginBottom: 0, width: "100%" }}
                                >
                                  <Input
                                    value={entry.fee}
                                    onChange={(e) =>
                                      handleFeeChange(e, index, "fee")
                                    }
                                    placeholder="Entry Fee"
                                  />
                                </Form.Item>

                                {/* Editing the Entry Fees */}

                                <div>
                                  {entryFees.length > 1 && (
                                    <Button
                                      onClick={() => handleRemoveEntry(index)}
                                    >
                                      Remove
                                    </Button>
                                  )}
                                </div>
                              </div>
                              <div style={{ marginTop: "20px" }}>
                                {index === entryFees.length - 1 && (
                                  <Button onClick={addEntryField}>
                                    Add Entry
                                  </Button>
                                )}
                              </div>
                            </>
                          ))}
                        </Form.Item>
                      </div>

                      <Form.Item label="Contact Persons">
                        {contactPerson.map((entry, index) => (
                          <>
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                                gap: "10px",
                              }}
                            >
                              <Form.Item
                                label="Enter Name"
                                style={{ marginBottom: 0, width: "100%" }}
                              >
                                <Input
                                  value={entry.name}
                                  onChange={(e) =>
                                    handleContactChange(e, index)
                                  }
                                  placeholder="Mr. John Doe"
                                />
                              </Form.Item>
                              <Form.Item
                                label="Designation"
                                style={{ marginBottom: 0, width: "100%" }}
                              >
                                <Input
                                  value={entry.position}
                                  onChange={(e) =>
                                    handleContactPositionChange(e, index)
                                  }
                                  placeholder="Chief Arbiter"
                                />
                              </Form.Item>
                              <Form.Item
                                label="Contact Number"
                                style={{ marginBottom: 0, width: "100%" }}
                              >
                                <Input
                                  value={entry.number}
                                  onChange={(e) =>
                                    handleContactNumberChange(e, index)
                                  }
                                  placeholder="9999988888"
                                />
                              </Form.Item>
                              <div>
                                {contactPerson.length > 1 && (
                                  <Button
                                    onClick={() =>
                                      handleContactRemoveEntry(index)
                                    }
                                  >
                                    Remove
                                  </Button>
                                )}
                              </div>
                            </div>
                            <div style={{ marginTop: "20px" }}>
                              {index === contactPerson.length - 1 && (
                                <Button onClick={addContactField}>
                                  Add Entry
                                </Button>
                              )}
                            </div>
                          </>
                        ))}
                      </Form.Item>
                    </div>

                    <div style={{ width: "100%" }}>
                      <div
                        style={{ backgroundColor: "#f9f9f9", padding: "20px" }}
                      >
                        <div>
                          <h2>Tournament Media</h2>
                        </div>

                        <Form.Item label="Brochure" style={{ width: "100%" }}>
                          <Upload
                            accept=".pdf"
                            beforeUpload={(file) => handleBrochureUpload(file)}
                            fileList={brochure ? [brochure] : []}
                            onRemove={() => setBrochure(null)}
                          >
                            <Button icon={<UploadOutlined />} size="small">
                              Select File
                            </Button>
                          </Upload>
                          {!imageSizeValid && brochure && (
                            <p style={{ color: "red" }}>
                              Image size exceeds the allowed limit (
                              {MAX_FILE_SIZE_MB} MB).
                            </p>
                          )}
                          {brochurePreview && (
                            <div>
                              <a
                                href={brochurePreview}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Preview Brochure
                              </a>
                            </div>
                          )}
                        </Form.Item>
                        <Form.Item
                          label="Brochure URL"
                          style={{ marginBottom: 0, width: "100%" }}
                        >
                          <Input
                            value={brochureUrl}
                            onChange={(e) => setBrochureUrl(e.target.value)}
                            placeholder="https://download.com/one.pdf"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    {/* div 2 end here  */}
                  </div>

                  <div style={{ marginTop: "40px" }}>
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      onClick={handleSubmit}
                    >
                      Update Tournament
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default EditTournament;
