import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/auth-context";
import "./MainNavigation.css";
import { NavLink } from "react-router-dom";
import { useHttpClient } from "../hooks/http-hook";
import logo from "../../../img/logo-white-bill.png";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import axios from "axios";

const MainNavigation = (props) => {
  const auth = useContext(AuthContext);
  const [profile, setProfile] = useState();
  // const [isAdmin, setIsAdmin] = useState(false);
  const [reviewTournaments, setReviewTournaments] = useState([]);
  const { isLoading, error, sendRequest } = useHttpClient();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}profile/${auth.userId}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        setProfile(response.data);
        // setIsAdmin(response.data.isAdmin);

        // if (response.data.isAdmin) {
        //   const tournamentsResponse = await axios.get(
        //     `${process.env.REACT_APP_BACKEND_URL}tournaments${auth.userId}`,
        //     {
        //       headers: { Authorization: `Bearer ${auth.token}` },
        //     }
        //   );
        //   setReviewTournaments(tournamentsResponse.data);
        // }
      } catch (err) {
        console.log(err);
      }
    };
    fetchProfile();
  }, [auth.token, auth.userId]);

  // Get subscription information
  // const subscriptionResponse = await axios.get(
  //   `${process.env.REACT_APP_BACKEND_URL}subscription/${response.data.stripeSubscriptionId}`,
  //   {
  //     headers: { Authorization: `Bearer ${auth.token}` },
  //   }
  // );
  // setSubscription(subscriptionResponse.data);
  // console.log("subscription.data", subscriptionResponse.data);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <div style={{ padding: "2.2rem" }}>
        <img style={{ width: "100%" }} src={logo} alt="logo" />
        {/* <h1 style={{ color: "white", fontSize: "1.2rem", margin: "0" }}>
          BillSubmit
        </h1> */}
      </div>
      <ul className="nav-links">
        <li>
          <NavLink to="/" exact={true}>
            My Tournaments
          </NavLink>
        </li>

        {auth.userId && (
          <li>
            <NavLink to={`/participants/${auth.userId}`} exact={true}>
              Participants
            </NavLink>
          </li>
        )}

        {/* <li>
          <NavLink to="/all-uploaded" exact={true}>
            All Uploaded
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink to="/pending-payment" exact={true}>
            Pending Payment
          </NavLink>
        </li>
        <li>
          <NavLink to="/paid-bills" exact={true}>
            Paid Bill
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink to="/billing" exact={true}>
            Manage Billing
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink to="/settings" exact={true}>
            Settings
          </NavLink>
        </li> */}
        <li>
          <NavLink to="/logout" onClick={auth.logout} exact={true}>
            Logout
          </NavLink>
        </li>
        <li>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <Avatar
              style={{ backgroundColor: "#deebf9", color: "#808080" }}
              size={30}
              icon={<UserOutlined />}
            />
            <p style={{ color: "white", margin: 0 }}>{profile?.name}</p>
          </div>
        </li>
      </ul>
      <div>
        {/* <div>
          {subscription && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                margin: "1.2rem",
                padding: "10px",
              }}
            >
              <img style={{ width: "2.2rem" }} src={diamond} alt="diamond" />
              <div>
                <h3 style={{ color: "white", margin: 0 }}>
                  {subscription ? "Yearly Plan" : "Not Subscribed"}
                </h3>
                <p style={{ color: "white", margin: 0, marginTop: "4px" }}>
                  {subscription ? subscription.status : "Not Subscribed"}
                </p>
              </div>
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default MainNavigation;
