import React, { useEffect, useState, useContext } from "react";
import MainNavigation from "../../shared/menu/MainNavigation";
import "./Dashboard.css";
import { Link } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import axios from "axios";
import AdminDashboard from "./AdminDashboard";

import moment from "moment";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import {
  Layout,
  Space,
  Table,
  Tag,
  Input,
  DatePicker,
  Button,
  Select,
  Alert,
  Drawer,
  Modal,
  Switch,
} from "antd";
const { Content, Sider } = Layout;
const { Search } = Input;
const { Option } = Select;

function Dashboard() {
  const auth = useContext(AuthContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState("");
  const { isLoading, error, sendRequest } = useHttpClient();
  const [tournaments, setTournaments] = useState([]);
  const [filteredTournaments, setFilteredTournaments] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [tournamentToReview, setTournamentToReview] = useState(null);

  // console.log(auth.token);

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}profile/${auth.userId}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        setIsAdmin(response.data.isAdmin);
        setLoading(false);
        setProfile(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProfile();
  }, [auth.token, auth.userId]);

  useEffect(() => {
    const fetchTournaments = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}tournaments/${auth.userId}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        console.log(response);
        const formattedTournaments = response.data.map((tournament) => ({
          id: tournament._id,
          name: tournament.name,
          city: tournament.city,
          state: tournament.state,
          startDate: new Date(tournament.startDate),
          endDate: new Date(tournament.endDate),
          uploadedAt: new Date(tournament.uploadedAt),
          status: tournament.status,
        }));

        formattedTournaments.sort(
          (a, b) => new Date(b.uploadedAt) - new Date(a.uploadedAt)
        );

        setTournaments(formattedTournaments);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchTournaments();
  }, [auth.userId, auth.token]);

  useEffect(() => {
    let filteredResults = tournaments.filter((tournament) => {
      let isTextMatched = true;
      let isStateMatched = true;
      let isMonthMatched = true;
      let isDateRangeMatched = true;

      if (searchText) {
        isTextMatched = tournament.name
          .toLowerCase()
          .includes(searchText.toLowerCase());
      }

      if (selectedState) {
        isStateMatched =
          tournament.state.toLowerCase() === selectedState.toLowerCase();
      }

      if (selectedMonth) {
        isMonthMatched =
          tournament.startDate.getMonth() + 1 === parseInt(selectedMonth);
      }

      if (dateRange.length > 0) {
        const [start, end] = dateRange;
        isDateRangeMatched =
          tournament.startDate >= start && tournament.endDate <= end;
      }

      return (
        isTextMatched && isStateMatched && isMonthMatched && isDateRangeMatched
      );
    });

    setFilteredTournaments(filteredResults);
  }, [tournaments, searchText, selectedState, selectedMonth, dateRange]);

  useEffect(() => {
    let filteredResults = tournaments.filter(
      (tournament) =>
        tournament.status === "in-review" ||
        tournament.status === "verified" ||
        tournament.status === "live" ||
        tournament.status === "draft" ||
        tournament.status === "suspended"
    );

    setFilteredTournaments(filteredResults);
  }, [tournaments]);

  if (isAdmin) {
    return <AdminDashboard />;
  }

  const deleteHandler = async (userId) => {
    if (window.confirm("Are you sure you want to delete this tournament?")) {
      setLoading(true);
      try {
        await axios.delete(
          `${process.env.REACT_APP_BACKEND_URL}tournaments/delete/${userId}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        setLoading(false);

        setTournaments(
          tournaments.filter((tournament) => tournament.id !== userId)
        );
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleStateChange = (value) => {
    setSelectedState(value);
  };

  const handleMonthChange = (value) => {
    setSelectedMonth(value);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const clearFilters = () => {
    setSearchText("");
    setSelectedState("");
    setSelectedMonth("");
    setDateRange([]);
  };

  // const handleReviewToggle = (tournament) => {
  //   console.log("Tournament to review:", tournament);
  //   setTournamentToReview(tournament);
  //   setConfirmModalVisible(true);
  // };

  const handleReviewToggle = (tournament) => {
    console.log("Tournament to review:", tournament);
    setTournamentToReview(tournament);
    if (tournament.status === "draft") {
      setConfirmModalVisible(true); // Open confirmation modal if status is "draft"
    } else if (tournament.status === "in-review") {
      setConfirmModalVisible(true); // Open confirmation modal if status is "in-review"
    } else {
      // If status is "verified", "live", or "suspended", do nothing
      return;
    }
  };

  const toggleReviewStatus = async (tournament) => {
    try {
      const updatedStatus =
        tournament.status === "in-review" ? "draft" : "in-review";

      // Send request to update tournament status
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}tournaments/update/${tournament.id}/status`,
        { status: updatedStatus },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      );

      // Update status locally
      const updatedTournaments = tournaments.map((t) => {
        if (t.id === tournament.id) {
          return { ...t, status: updatedStatus };
        }
        return t;
      });

      setTournaments(updatedTournaments);
    } catch (error) {
      console.log(error);
    }
  };

  const confirmReview = async () => {
    console.log("Confirming review for tournament:", tournamentToReview);
    try {
      // Toggle the status when confirming the review
      toggleReviewStatus(tournamentToReview);
      setConfirmModalVisible(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cancelReview = () => {
    setConfirmModalVisible(false);
  };

  const columns = [
    {
      title: "Tournament Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link
          to={`/tournaments/details/${record.id}`}
          style={{ textDecoration: "none" }}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text) => <span>{text.toLocaleDateString()}</span>,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text) => <span>{text.toLocaleDateString()}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <Space direction="vertical">
          <Switch
            checked={status === "in-review"}
            onChange={() => handleReviewToggle(record)}
          />
          <Tag color={status === "draft" ? "grey" : "orange"}>
            {status.toUpperCase()}
          </Tag>
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size={4}>
          <Link to={`/tournaments/details/${record.id}`}>
            <Button type="link" icon={<EyeOutlined />} />
          </Link>
          <Link to={`/tournaments/edit/${record.id}`}>
            <Button
              type="link"
              icon={<EditOutlined />}
              style={{ color: "orange" }}
            />
          </Link>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => deleteHandler(record.id)}
            style={{ color: "red" }}
          />
        </Space>
      ),
    },
  ];

  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Link to="/create-tournament">
          <Button type="primary" style={{ marginLeft: 1000, marginTop: 10 }}>
            Create New Tournament
          </Button>
        </Link>

        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <Input.Search
              placeholder="Search by tournament name"
              allowClear
              onSearch={handleSearch}
              style={{ width: 300, marginBottom: 20 }}
            />
            <Select
              placeholder="Select state"
              allowClear
              style={{
                width: 200,
                marginRight: 20,
                marginBottom: 20,
                marginLeft: 10,
              }}
              onChange={handleStateChange}
            >
              {Array.from(
                new Set(tournaments.map((tournament) => tournament.state))
              ).map((state) => (
                <Select.Option key={state} value={state}>
                  {state}
                </Select.Option>
              ))}
            </Select>
            <Select
              placeholder="Select month"
              allowClear
              style={{ width: 200, marginRight: 20, marginBottom: 20 }}
              onChange={handleMonthChange}
            >
              {[...Array(12).keys()].map((month) => (
                <Select.Option key={month + 1} value={month + 1}>
                  {moment().month(month).format("MMMM")}
                </Select.Option>
              ))}
            </Select>
            <DatePicker.RangePicker
              style={{ marginBottom: 20 }}
              onChange={handleDateRangeChange}
            />
            <Button
              type="primary"
              onClick={clearFilters}
              style={{ marginLeft: 10 }}
            >
              Clear Filters
            </Button>
            <Table
              dataSource={filteredTournaments}
              columns={columns}
              loading={loading}
              rowKey="id"
            />
          </div>
        </Content>
      </Layout>
      <Modal
        title="Confirmation"
        open={confirmModalVisible}
        onCancel={() => setConfirmModalVisible(false)}
        onOk={confirmReview}
      >
        <p>
          {tournamentToReview && tournamentToReview.status === "draft"
            ? "Are you sure you want to submit the tournament for Review?"
            : "Are you sure you want to set the status to Draft?"}
        </p>
      </Modal>
    </Layout>
  );
}

export default Dashboard;
