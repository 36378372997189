import React, { useState, useEffect, useContext } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
// import jwt from 'jsonwebtoken';
import { AuthContext } from "./components/shared/context/auth-context";
import { useAuth } from "./components/shared/hooks/auth-hook";
import Auth from "./components/auth/Auth";
import Dashboard from "./components/dashboard/pages/Dashboard";
// import Campaign from "./components/campaigns/pages/Campaign";
import AdminDashboard from "./components/dashboard/pages/AdminDashboard";
// import AdminNavigation from "./components/shared/menu/AdminNavigation";
import AdminTournamentDetails from "./components/Tournaments/pages/AdminDetails";
import ParticipantsPage from "../src/components/Participants/Pages/ParticipantsPage";

import Participants from "./components/Participants/Pages/index";
import { SuccessProvider } from "./components/shared/context/success-context";
import TournamentDetails from "./components/Tournaments/pages/Details";
import CreateTournament from "./components/Tournaments/pages/Create";
import EditTournament from "./components/Tournaments/pages/EditTournament";
import VerifyEmail from "./components/auth/VerifyEmail";
import Verified from "./components/auth/Verified";
import ResetPassword from "./components/auth/ResetPassword";
import NewPassword from "./components/auth/NewPassword";

function App() {
  const { token, login, logout, userId, isLoading, isAdmin } = useAuth();
  console.log("isAdmin:", isAdmin);
  // const isAdmin = true;
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      setIsCheckingAuth(false);
    }
  }, [isLoading, token]);

  let routes;

  if (isLoading || isCheckingAuth) {
    routes = <div>Loading...</div>;
  } else if (token) {
    // const decodedtoken = jwt_decode(token);

    console.log("isAdmin:", isAdmin);
    routes = (
      <Switch>
        <Route exact path="/">
          {isAdmin ? <AdminDashboard /> : <Dashboard />}
        </Route>
        <Route exact path="/participants/:id">
          <Participants />
        </Route>
        <Route exact path="/participants/:userId/:tournamentId">
          <ParticipantsPage />
        </Route>
        <Route path="/admin-tournament-details/:id">
          <AdminTournamentDetails />
        </Route>
        <Route exact path="/create-tournament">
          <CreateTournament />
        </Route>
        {/* <Route exact path="/create-tournament2/:id">
            <CreateTournament2 />
          </Route> */}
        <Route exact path="/tournaments/details/:id">
          {isAdmin ? <AdminTournamentDetails /> : <TournamentDetails />}
        </Route>
        <Route exact path="/tournaments/edit/:id">
          <EditTournament />
        </Route>
        {/* <Route exact path="/bill/edit/detail/:id">
            <EditBillDetails />
          </Route> */}
        <Redirect to="/" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route exact path="/auth">
          <Auth />
        </Route>
        <Route exact path="/verify-email">
          <VerifyEmail />
        </Route>
        <Route exact path="/verified">
          <Verified />
        </Route>
        <Route exact path="/reset-password">
          <ResetPassword />
        </Route>
        <Route exact path="/new-password/:token">
          <NewPassword />
        </Route>
        <Redirect to="/auth" />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        login: login,
        logout: logout,
        isAdmin: isAdmin,
      }}
    >
      <SuccessProvider>
        <Router>{routes}</Router>
      </SuccessProvider>
    </AuthContext.Provider>
  );
}

export default App;
