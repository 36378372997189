import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../context/auth-context";
import "./MainNavigation.css";
import logo from "../../../img/logo-white-bill.png";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import axios from "axios";

const AdminNavigation = () => {
  const auth = useContext(AuthContext);
  const [profile, setProfile] = useState();
  const [reviewTournaments, setReviewTournaments] = useState([]);

  useEffect(() => {
    const fetchReviewTournaments = async () => {
      try {
        // Fetch review tournaments
        const tournamentsResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}admin/tournaments`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        setReviewTournaments(tournamentsResponse.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchReviewTournaments();
  }, [auth.token]);

  const handleLogout = () => {
    auth.logout();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <div style={{ padding: "2.2rem" }}>
        <img style={{ width: "100%" }} src={logo} alt="logo" />
      </div>
      <ul className="nav-links">
        <li>
          <NavLink to="/" exact={true}>
            All Tournaments
          </NavLink>
        </li>
        <li>
          <NavLink to="/logout" onClick={auth.logout} exact={true}>
            Logout
          </NavLink>
        </li>
        <li>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <Avatar
              style={{ backgroundColor: "#deebf9", color: "#808080" }}
              size={30}
              icon={<UserOutlined />}
            />
            <p style={{ color: "white", margin: 0 }}>{profile?.name}</p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default AdminNavigation;
