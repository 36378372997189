import React, { useEffect, useState, useContext, useRef } from "react";
import MainNavigation from "../../shared/menu/MainNavigation";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import {
  Layout,
  Form,
  Input,
  Button,
  Radio,
  Upload,
  Select,
  Result,
  DatePicker,
  Spin,
  TimePicker,
  Switch,
  Alert,
} from "antd";
import indianStates from "../../../constants";
import Dropzone from "react-dropzone";
import axios from "axios";
import moment from "moment";
import { Helmet } from "react-helmet";
import {
  UploadOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { Option } = Select;

const { Dragger } = Upload;
const { Content, Sider } = Layout;
const { TextArea } = Input;

function CreateTournament() {
  const [loading, setLoading] = useState(false);
  const [draftMode, setDraftMode] = useState(true);
  const [error, setError] = useState();
  const [ShowSuccessMessage, setShowSuccessMessage] = useState();
  const { isLoading, sendRequest } = useHttpClient();
  // const [isFormComplete, setIsFormComplete] = useState(false);
  const auth = useContext(AuthContext);
  const [name, setName] = useState();
  const [organization, setOrganization] = useState();
  const [totalPrize, setTotalPrize] = useState();
  const [address, setAddress] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  // const [timeControl, setTimeControl] = useState();
  const [lastDate, setLastDate] = useState("24th January");
  const [aicfEventCode, setAicfEventCode] = useState();
  // const [tournamentRules, setTournamentRules] = useState();
  // const [ImportantPoints, setImportantPoints] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [entryFees, setEntryFees] = useState([{ category: "", fee: "" }]);
  const [contactPerson, setContactPerson] = useState([
    { name: "", position: "", number: "" },
  ]);
  // const [bannerImage, setBannerImage] = useState(null);
  const [brochure, setBrochure] = useState(null);
  // const [bannerImagePreview, setBannerImagePreview] = useState(null);
  const [brochurePreview, setBrochurePreview] = useState(null);
  const [imageSizeValid, setImageSizeValid] = useState(true);
  // const [prizes, setPrizes] = useState([
  //   { category: "", prizes: [{ position: "", prize: "" }] },
  // ]);
  // const [schedule, setSchedule] = useState([
  //   {
  //     text: "",
  //     date: null,
  //     time: null,
  //   },
  // ]);

  const acceptedFileTypes = {
    "image/png": ".png",
    "image/jpeg": ".jpeg",
    "application/pdf": ".pdf",
  };

  const MAX_FILE_SIZE_MB = 5;

  const history = useHistory();

  const toggleDraftMode = () => {
    setDraftMode((prevMode) => !prevMode);
  };

  // const buttonLabel = draftMode ? "Save As Draft" : "Create Tournament";

  const handleCategoryChange = (e, index) => {
    const updatedEntryFees = [...entryFees];
    updatedEntryFees[index].category = e.target.value;
    setEntryFees(updatedEntryFees);
  };

  const handleFeeChange = (e, index) => {
    const updatedEntryFees = [...entryFees];
    updatedEntryFees[index].fee = e.target.value;
    setEntryFees(updatedEntryFees);
  };

  const addEntryField = () => {
    setEntryFees([...entryFees, { category: "", fee: "" }]);
  };

  const handleRemoveEntry = (index) => {
    const updatedEntryFees = [...entryFees];
    updatedEntryFees.splice(index, 1);
    setEntryFees(updatedEntryFees);
  };

  const handleContactChange = (e, index) => {
    const updatedContact = [...contactPerson];
    updatedContact[index].name = e.target.value;
    setContactPerson(updatedContact);
  };

  const handleContactPositionChange = (e, index) => {
    const updatedPosition = [...contactPerson];
    updatedPosition[index].position = e.target.value;
    setContactPerson(updatedPosition);
  };

  const handleContactNumberChange = (e, index) => {
    const updatedNumber = [...contactPerson];
    updatedNumber[index].number = e.target.value;
    setContactPerson(updatedNumber);
  };

  const addContactField = () => {
    setContactPerson([
      ...contactPerson,
      { name: "", position: "", number: "" },
    ]);
  };

  const handleContactRemoveEntry = (index) => {
    const updatedContact = [...contactPerson];
    updatedContact.splice(index, 1);
    setContactPerson(updatedContact);
  };

  // const handleBannerImageUpload = (file) => {
  //   if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
  //     setImageSizeValid(false);
  //     ToastContainer.error(
  //       `File size exceeds the limit of ${MAX_FILE_SIZE_MB}MB`,
  //       {
  //         position: toast.POSITION.TOP_CENTER,
  //         autoClose: 3000,
  //         hideProgressBar: true,
  //       }
  //     );
  //     return false;
  //   }
  //   setImageSizeValid(true);
  //   setBannerImage(file);
  //   return false; // Prevent default upload behavior
  // };

  const handleBrochureUpload = (file) => {
    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      setImageSizeValid(false);
      ToastContainer.error(
        `File size exceeds the limit of ${MAX_FILE_SIZE_MB}MB`,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: true,
        }
      );
      return false;
    }
    setImageSizeValid(true);
    setBrochure(file);
    return false; // Prevent default upload behavior
  };

  // useEffect(() => {
  //   if (bannerImage) {
  //     setBannerImagePreview(URL.createObjectURL(bannerImage));
  //   } else {
  //     setBannerImagePreview(null);
  //   }
  // }, [bannerImage]);

  useEffect(() => {
    if (brochure) {
      setBrochurePreview(URL.createObjectURL(brochure));
    } else {
      setBrochurePreview(null);
    }
  }, [brochure]);

  // const saveDraft = async () => {
  //   setLoading(true);

  //   if (!name) {
  //     throw new Error("Tournament name is required to save as draft.");
  //   }

  //   // Prepare the form data
  //   const entryFeesArray = entryFees.map((entry) => ({
  //     category: entry.category,
  //     fee: entry.fee,
  //   }));

  //   const contactPersonArray = contactPerson.map((entry) => ({
  //     name: entry.name,
  //     position: entry.position,
  //     number: entry.number,
  //   }));

  // const prizeData = prizes.map((prize) => ({
  //   category: prize.category,
  //   prizes: prize.prizes.map((subPrize) => ({
  //     position: subPrize.position,
  //     prize: subPrize.prize,
  //   })),
  // }));

  // const scheduleData = schedule.map((entry) => ({
  //   text: entry.text,
  //   date: entry.date,
  //   time: entry.time,
  // }));

  //   try {
  //     const formData = new FormData();
  //     formData.append("name", name);
  //     formData.append("organization", organization);
  //     formData.append("totalPrize", totalPrize);
  //     formData.append("address", address);
  //     formData.append("city", city);
  //     // formData.append("timeControl", timeControl);
  //     formData.append("lastDate", lastDate);
  //     formData.append("state", state);
  //     formData.append("startDate", startDate);
  //     formData.append("endDate", endDate);
  //     // formData.append("tournamentRules", tournamentRules);
  //     // formData.append("importantPoints", ImportantPoints);
  //     // formData.append("schedule", JSON.stringify(scheduleData));
  //     formData.append("entryFees", JSON.stringify(entryFeesArray));
  //     formData.append("contactPerson", JSON.stringify(contactPersonArray));
  //     // formData.append("prizes", JSON.stringify(prizeData));
  //     formData.append("creator", auth.userId);
  //     // formData.append("bannerImage", bannerImage); // Append the banner image file
  //     formData.append("brochure", brochure); // Append the brochure file
  //     formData.append("isDraft", true); // Set isDraft to true for draft saving

  //     // Send the draft data to the backend
  //     const response = await axios.post(
  //       process.env.REACT_APP_BACKEND_URL + "create-tournament",
  //       formData,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${auth.token}`,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );
  //     setLoading(false);
  //     toast.success("Tournament saved as draft successfully", {
  //       position: toast.POSITION.TOP_CENTER,
  //       autoClose: 3000,
  //       hideProgressBar: true,
  //     });

  //     const resetFormFields = () => {
  //       setName("");
  //       setAddress("");
  //       setCity("");
  //       setState("");
  //       setStartDate(null);
  //       setEndDate(null);
  //       setEntryFees([{ category: "", fee: "" }]);
  //       // setBannerImage(null);
  //       setBrochure(null);
  //       // setBannerImagePreview(null);
  //       setBrochurePreview(null);
  //       setContactPerson([{ name: "", position: "", number: "" }]);
  //       // setTournamentRules("");
  //       // setImportantPoints("");
  //       // setSchedule([{ text: "", date: null, time: null }]);
  //       // setPrizes([]);
  //       setOrganization("");
  //       setTotalPrize("");
  //       // setTimeControl("");
  //       setLastDate("");
  //     };

  //     // Reset form fields if draft saved successfully
  //     resetFormFields();
  //   } catch (error) {
  //     console.log(error);
  //     toast.error("Failed to save draft", {
  //       position: toast.POSITION.TOP_CENTER,
  //       autoClose: 3000,
  //       hideProgressBar: true,
  //     });
  //   }
  // };

  // const handleSaveDraft = async () => {
  //   let firstEmptyField = null;
  //   if (!name || !startDate || !endDate) {
  //     // Loop through all form fields to find the first empty required field
  //     const formFields = [
  //       { name: "name", value: name },
  //       { name: "startDate", value: startDate },
  //       { name: "endDate", value: endDate },
  //       // Add more form fields here if needed
  //     ];

  //     for (const field of formFields) {
  //       if (!field.value) {
  //         firstEmptyField = field.name;
  //         break;
  //       }
  //     }

  //     if (firstEmptyField) {
  //       toast.error(
  //         "Name, start date, and end date are required to save the draft.",
  //         { autoClose: 5000 }
  //       );

  //       // Scroll to the empty required field
  //       const emptyFieldElement = document.getElementById(firstEmptyField);
  //       if (emptyFieldElement) {
  //         emptyFieldElement.scrollIntoView({ behavior: "smooth" });
  //       }
  //       return;
  //     }
  //   }

  //   await saveDraft(); // Call the saveDraft function
  // };

  const handleSubmit = async (e, isDraft = true) => {
    setLoading(true);
    // console.log("Schedule data before sending:", scheduleData);

    const status = draftMode ? "draft" : "in-review";

    const entryFeesArray = entryFees.map((entry) => ({
      category: entry.category,
      fee: entry.fee,
    }));

    const contactPersonArray = contactPerson.map((entry) => ({
      name: entry.name,
      position: entry.position,
      number: entry.number,
    }));

    // const prizeData = prizes.map((prize) => ({
    //   category: prize.category,
    //   prizes: prize.prizes.map((subPrize) => ({
    //     position: subPrize.position,
    //     prize: subPrize.prize,
    //   })),
    // }));

    // const scheduleData = schedule.map((entry) => ({
    //   text: entry.text,
    //   date: entry.date,
    //   time: entry.time,
    // }));

    // Append the schedule data to the form data

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("organization", organization);
      formData.append("totalPrize", totalPrize);
      formData.append("address", address);
      formData.append("city", city);
      // formData.append("timeControl", timeControl);
      formData.append("lastDate", lastDate);
      formData.append("state", state);
      formData.append("startDate", startDate);
      formData.append("endDate", endDate);
      // formData.append("tournamentRules", tournamentRules);
      // formData.append("importantPoints", ImportantPoints);
      // formData.append("schedule", JSON.stringify(scheduleData));
      formData.append("entryFees", JSON.stringify(entryFeesArray));
      formData.append("contactPerson", JSON.stringify(contactPersonArray));
      // formData.append("prizes", JSON.stringify(prizeData));
      formData.append("creator", auth.userId);
      // formData.append("bannerImage", bannerImage); // Append the banner image file
      formData.append("brochure", brochure); // Append the brochure file
      formData.append("aicfEventCode", aicfEventCode);

      // console.log(schedule);

      console.log("form data with schedule:", formData);

      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "create-tournament",
        formData,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
          },
        }
      );
      console.log("Response:", response.data);
      setLoading(false);

      // Reset the form fields
      setName("");
      setAddress("");
      setCity("");
      setState("");
      setStartDate(null);
      setEndDate(null);
      setEntryFees([{ category: "", fee: "" }]);
      // setBannerImage(null);
      setBrochure(null);
      // setBannerImagePreview(null);
      setBrochurePreview(null);
      setContactPerson([{ name: "", position: "", number: "" }]);
      // setTournamentRules("");
      // setImportantPoints("");
      // setSchedule([{ text: "", date: null, time: null }]);
      // setPrizes([]);
      setOrganization("");
      setTotalPrize("");
      // setTimeControl("");
      setLastDate("");
      setAicfEventCode("");

      if (isDraft) {
        toast.success("Tournament saved as draft successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: true,
        });
        history.push("/all/tournaments");
      } else {
        toast.success("Tournament created successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: true,
        });

        // console.log(response.data); // Handle the response data as needed
        history.push("/all/tournaments");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to create tournament", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

  // const handlePrizeCategoryChange = (e, index) => {
  //   const updatedPrizes = [...prizes];
  //   updatedPrizes[index].category = e.target.value;
  //   setPrizes(updatedPrizes);
  // };

  // const handlePrizePositionChange = (e, index, subIndex) => {
  //   const updatedPrizes = [...prizes];
  //   updatedPrizes[index].prizes[subIndex].position = e.target.value;
  //   setPrizes(updatedPrizes);
  // };

  // const handlePrizeValueChange = (e, index, subIndex) => {
  //   const updatedPrizes = [...prizes];
  //   updatedPrizes[index].prizes[subIndex].prize = e.target.value;
  //   setPrizes(updatedPrizes);
  // };

  // const handleAddPrizeRow = (categoryIndex) => {
  //   const updatedPrizes = [...prizes];
  //   updatedPrizes[categoryIndex].prizes.push({ position: "", prize: "" });
  //   setPrizes(updatedPrizes);
  // };

  // const handleRemovePrizeRow = (categoryIndex, rowIndex) => {
  //   const updatedPrizes = [...prizes];
  //   updatedPrizes[categoryIndex].prizes.splice(rowIndex, 1);
  //   setPrizes(updatedPrizes);
  // };

  // const handleRemovePrize = (index) => {
  //   const updatedPrizes = [...prizes];
  //   updatedPrizes.splice(index, 1);
  //   setPrizes(updatedPrizes);
  // };

  // const addCategory = () => {
  //   setPrizes([
  //     ...prizes,
  //     { category: "", prizes: [{ position: "", prize: "" }] },
  //   ]);
  // };

  // const handleScheduleTextChange = (e, index) => {
  //   const updatedSchedule = [...schedule];
  //   updatedSchedule[index].text = e.target.value;
  //   setSchedule(updatedSchedule);
  // };

  // const handleScheduleDateChange = (date, index) => {
  //   const updatedSchedule = [...schedule];
  //   updatedSchedule[index].date = date;
  //   setSchedule(updatedSchedule);
  // };

  // const handleScheduleTimeChange = (time, index) => {
  //   console.log("Time selected:", time);
  //   const updatedSchedule = [...schedule];
  //   updatedSchedule[index].time = time;
  //   console.log("Updated schedule:", updatedSchedule);
  //   setSchedule(updatedSchedule);
  // };

  // const handleAddSchedule = () => {
  //   setSchedule([...schedule, { text: "", date: null, time: null }]);
  // };

  // const handleRemoveSchedule = (indexToRemove) => {
  //   setSchedule((prevSchedule) =>
  //     prevSchedule.filter((_, index) => index !== indexToRemove)
  //   );
  // };

  return (
    <Layout hasSider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Create New Tournament | Chess7</title>
        <link rel="canonical" href="https://app.billsubmit.com/upload" />
      </Helmet>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
            fontWeight: "bold",
          }}
        >
          <ToastContainer />

          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            {loading ? <Spin /> : !loading}

            <div>
              <div style={{ marginTop: "20px" }}>
                <Form
                  layout="vertical"
                  onFinish={handleSubmit}
                  onFinishFailed={(errorInfo) => {
                    if (errorInfo.errorFields.length > 0) {
                      const firstErrorField = errorInfo.errorFields[0];
                      const inputElement = document.getElementById(
                        firstErrorField.name
                      );
                      if (inputElement) {
                        inputElement.scrollIntoView({ behavior: "smooth" });
                      }
                    }
                  }}
                >
                  {error && (
                    <Alert
                      message={error}
                      type="error"
                      showIcon
                      style={{ marginBottom: "1rem" }}
                    />
                  )}
                  <div
                  // style={{
                  //   display: "flex",
                  //   alignItems: "flex-start",
                  //   justifyContent: "space-between",
                  //   gap: "20px",
                  // }}
                  >
                    {/* div 1 here */}

                    <div style={{ width: "100%" }}>
                      <div
                        className="main-heading"
                        style={{
                          marginBottom: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <h2>Create New Tournament</h2>
                        </div>
                        <Switch
                          checked={draftMode}
                          onChange={toggleDraftMode}
                        />
                        <span>{draftMode ? "Draft Mode" : "Review Mode"}</span>
                      </div>
                      <Form.Item
                        label="Enter Event Name"
                        name="name"
                        required
                        rules={[
                          {
                            required: true,
                            message: "Please input your event name!",
                          },
                        ]}
                      >
                        <Input
                          id="name"
                          placeholder="Delhi Chess Tournament - 2023"
                          type="text"
                          size="large"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Form.Item>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "20px",
                        }}
                      >
                        <Form.Item
                          label="Organized by"
                          name="organiser"
                          style={{ width: "100%" }}
                          required
                          rules={[
                            {
                              required: true,
                              message: "Please input your organization name!",
                            },
                          ]}
                        >
                          <Input
                            id="organiser"
                            placeholder="Delhi Chess Association"
                            type="text"
                            size="large"
                            onChange={(e) => setOrganization(e.target.value)}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Total Prize Fund"
                          name="totalPrize"
                          style={{ width: "100%" }}
                          required
                          rules={[
                            {
                              required: true,
                              message: "Please input total prize fund!",
                            },
                          ]}
                        >
                          <Input
                            id="totalPrize"
                            placeholder="Rs 2,00,000"
                            type="text"
                            size="large"
                            onChange={(e) => setTotalPrize(e.target.value)}
                          />
                        </Form.Item>
                      </div>

                      <Form.Item
                        label="Event Address"
                        name="address"
                        required
                        rules={[
                          {
                            required: true,
                            message: "Please input your event name!",
                          },
                        ]}
                      >
                        <Input
                          id="address"
                          placeholder="Enter Event Address"
                          type="text"
                          size="large"
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </Form.Item>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "20px",
                        }}
                      >
                        <Form.Item
                          label="Enter Event City"
                          name="city"
                          style={{ width: "100%" }}
                          required
                          rules={[
                            {
                              required: true,
                              message: "Please input your event city!",
                            },
                          ]}
                        >
                          <Input
                            id="city"
                            placeholder="Enter Event City"
                            type="text"
                            size="large"
                            onChange={(e) => setCity(e.target.value)}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Enter Event State"
                          name="state"
                          style={{ width: "100%" }}
                          required
                          rules={[
                            {
                              required: true,
                              message: "Please select your event state!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Event State"
                            size="large"
                            onChange={setState}
                            value={state}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {indianStates.map((state) => (
                              <Option key={state} value={state}>
                                {state}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                      <Form.Item
                        label="Select Event Dates"
                        required
                        rules={[
                          {
                            type: "array",
                            required: true,
                            message: "Please select the date range!",
                          },
                        ]}
                      >
                        <DatePicker.RangePicker
                          style={{ width: "100%" }}
                          format="DD-MM-YYYY"
                          size="large"
                          onChange={(dates) => {
                            setStartDate(dates[0]);
                            setEndDate(dates[1]);
                          }}
                          id="dateRangePicker"
                        />
                      </Form.Item>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        {/* <Form.Item
                          label="Enter Time Control"
                          name="timeControl"
                          style={{ width: "100%", fontWeight: "bold" }}
                          required
                          rules={[
                            {
                              required: true,
                              message: "Please input time control",
                            },
                          ]}
                        >
                          <Input
                            id="timeControl"
                            placeholder="90 minutes + 30 seconds increment"
                            type="text"
                            size="large"
                            onChange={(e) => setTimeControl(e.target.value)}
                          />
                        </Form.Item> */}
                        <Form.Item
                          label="Last Date for Registration"
                          name="lastDate"
                          style={{ width: "100%", fontWeight: "bold" }}
                          required
                          rules={[
                            {
                              required: true,
                              message:
                                "Please input last date for registration",
                            },
                          ]}
                        >
                          <Input
                            id="lastDate"
                            placeholder="24th April, 2023"
                            type="text"
                            size="large"
                            onChange={(e) => setLastDate(e.target.value)}
                          />
                        </Form.Item>
                        <Form.Item
                          label="AICF Event Code"
                          name="aicfEventCode"
                          style={{ width: "100%", fontWeight: "bold" }}
                        >
                          <Input
                            id="aicfEventCode"
                            placeholder="309011"
                            type="text"
                            size="large"
                            onChange={(e) => setAicfEventCode(e.target.value)}
                          />
                        </Form.Item>
                      </div>

                      {/* <Form.Item
                        label="Enter All The Prizes"
                        name="prizes"
                        style={{ width: "100%", fontWeight: "bold" }}
                        rules={[
                          {
                            validator: (_, value) => {
                              if (!value || value.length === 0) {
                                return Promise.reject(
                                  "Please add at least one prize category"
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        {prizes.map((prize, categoryIndex) => (
                          <div
                            key={categoryIndex}
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "space-between",
                              gap: "20px",
                              marginTop: "5px",
                            }}
                          >
                            <div style={{ width: "40%" }}>
                              <Form.Item
                                label="Category Name"
                                name={["prizes", categoryIndex, "category"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input category name",
                                  },
                                ]}
                              >
                                <Input
                                  style={{ width: "100%" }}
                                  size="large"
                                  value={prize.category}
                                  onChange={(e) =>
                                    handlePrizeCategoryChange(e, categoryIndex)
                                  }
                                  placeholder="Category"
                                />
                              </Form.Item>
                            </div>
                            <div style={{ width: "60%" }}>
                              {prize.prizes.map((subPrize, rowIndex) => (
                                <div
                                  key={rowIndex}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 20,
                                    width: "100%",
                                  }}
                                >
                                  <div style={{ width: "100%" }}>
                                    <Form.Item
                                      label="Position"
                                      name={[
                                        "prizes",
                                        categoryIndex,
                                        "prizes",
                                        rowIndex,
                                        "position",
                                      ]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please input position",
                                        },
                                      ]}
                                    >
                                      <Input
                                        style={{
                                          width: "100%",
                                          marginBottom: "10px",
                                        }}
                                        size="large"
                                        value={subPrize.position}
                                        onChange={(e) =>
                                          handlePrizePositionChange(
                                            e,
                                            categoryIndex,
                                            rowIndex
                                          )
                                        }
                                        placeholder="Position"
                                      />
                                    </Form.Item>
                                  </div>
                                  <div style={{ width: "100%" }}>
                                    <Form.Item
                                      label="Prize"
                                      name={[
                                        "prizes",
                                        categoryIndex,
                                        "prizes",
                                        rowIndex,
                                        "prize",
                                      ]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please input prize",
                                        },
                                      ]}
                                    >
                                      <Input
                                        style={{
                                          width: "100%",
                                          marginBottom: "10px",
                                        }}
                                        size="large"
                                        value={subPrize.prize}
                                        onChange={(e) =>
                                          handlePrizeValueChange(
                                            e,
                                            categoryIndex,
                                            rowIndex
                                          )
                                        }
                                        placeholder="Prize"
                                      />
                                    </Form.Item>
                                  </div>

                                  <div
                                    onClick={() =>
                                      handleAddPrizeRow(categoryIndex)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <PlusCircleOutlined
                                      style={{
                                        color: "black",
                                        fontSize: "18px",
                                      }}
                                    />
                                  </div>

                                  <div
                                    onClick={() =>
                                      handleRemovePrizeRow(
                                        categoryIndex,
                                        rowIndex
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <DeleteOutlined
                                      style={{ color: "red", fontSize: "18px" }}
                                    />
                                  </div>
                                </div>
                              ))}

                              {prizes.length > 1 && (
                                <Button
                                  onClick={() =>
                                    handleRemovePrize(categoryIndex)
                                  }
                                >
                                  Remove Prize Category
                                </Button>
                              )}
                            </div>
                          </div>
                        ))}
                        <Button
                          onClick={addCategory}
                          type="default"
                          style={{ marginTop: 10, marginBottom: 10 }}
                        >
                          Add New Prize Category
                        </Button>
                      </Form.Item> */}

                      <div
                        style={{ backgroundColor: "#f9f9f9", padding: "20px" }}
                      >
                        <Form.Item label="Entry Fee Structure" name="entryFee">
                          {entryFees.map((entry, index) => (
                            <>
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "flex-end",
                                  gap: "10px",
                                }}
                              >
                                <Form.Item
                                  label="Category"
                                  name={["entryFee", index, "category"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input category",
                                    },
                                  ]}
                                  style={{ marginBottom: 0, width: "100%" }}
                                >
                                  <Input
                                    value={entry.category}
                                    onChange={(e) =>
                                      handleCategoryChange(e, index)
                                    }
                                    placeholder="Category"
                                  />
                                </Form.Item>
                                <Form.Item
                                  label="Entry Fee"
                                  name={["entryFee", index, "fee"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input entry fee",
                                    },
                                  ]}
                                  style={{ marginBottom: 0, width: "100%" }}
                                >
                                  <Input
                                    value={entry.fee}
                                    onChange={(e) => handleFeeChange(e, index)}
                                    placeholder="Entry Fee"
                                  />
                                </Form.Item>
                                <div>
                                  {entryFees.length > 1 && (
                                    <Button
                                      onClick={() => handleRemoveEntry(index)}
                                    >
                                      Remove
                                    </Button>
                                  )}
                                </div>
                              </div>
                              <div style={{ marginTop: "20px" }}>
                                {index === entryFees.length - 1 && (
                                  <Button
                                    onClick={addEntryField}
                                    type="default"
                                  >
                                    Add Entry
                                  </Button>
                                )}
                              </div>
                            </>
                          ))}
                        </Form.Item>
                      </div>
                      {/* <Form.Item
                        label="Tournament Rules"
                        name="tournamentRules"
                        style={{ marginTop: 20, width: "100%" }}
                        required
                        rules={[
                          {
                            required: true,
                            message: "Please input your tournament rules",
                          },
                        ]}
                      >
                        <TextArea
                          id="tournamentRules"
                          placeholder="Enter tournament rules"
                          type="text"
                          size="large"
                          onChange={(e) => setTournamentRules(e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Important Points to Remember"
                        name="importantPoints"
                        style={{ marginTop: 20, width: "100%" }}
                        required
                        rules={[
                          {
                            required: true,
                            message: "Please input Important Points",
                          },
                        ]}
                      >
                        <TextArea
                          id="importantPoints"
                          placeholder="Enter Important Points"
                          type="text"
                          size="large"
                          onChange={(e) => setImportantPoints(e.target.value)}
                        />
                      </Form.Item> */}

                      <Form.Item label="Contact Persons" name="contactPerson">
                        {contactPerson.map((entry, index) => (
                          <>
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                                gap: "10px",
                              }}
                            >
                              <Form.Item
                                label="Enter Name"
                                name={["contactPerson", index, "name"]}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please provide contact person's name",
                                  },
                                ]}
                                style={{ marginBottom: 0, width: "100%" }}
                              >
                                <Input
                                  value={entry.name}
                                  onChange={(e) =>
                                    handleContactChange(e, index)
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please provide contact Person",
                                    },
                                  ]}
                                  placeholder="Mr. John Doe"
                                />
                              </Form.Item>
                              <Form.Item
                                label="Designation"
                                name={["contactPerson", index, "position"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input designation",
                                  },
                                ]}
                                style={{ marginBottom: 0, width: "100%" }}
                              >
                                <Input
                                  value={entry.position}
                                  onChange={(e) =>
                                    handleContactPositionChange(e, index)
                                  }
                                  placeholder="Chief Arbiter"
                                />
                              </Form.Item>
                              <Form.Item
                                label="Contact Number"
                                name={["contactPerson", index, "number"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input the contact number",
                                  },
                                ]}
                                style={{ marginBottom: 0, width: "100%" }}
                              >
                                <Input
                                  value={entry.number}
                                  onChange={(e) =>
                                    handleContactNumberChange(e, index)
                                  }
                                  placeholder="9999988888"
                                />
                              </Form.Item>
                              <div>
                                {contactPerson.length > 1 && (
                                  <Button
                                    onClick={() =>
                                      handleContactRemoveEntry(index)
                                    }
                                  >
                                    Remove
                                  </Button>
                                )}
                              </div>
                            </div>
                            <div style={{ marginTop: "20px" }}>
                              {index === contactPerson.length - 1 && (
                                <Button
                                  onClick={addContactField}
                                  type="default"
                                >
                                  Add Entry
                                </Button>
                              )}
                            </div>
                          </>
                        ))}
                      </Form.Item>

                      {/* <Form.Item label="Tournament Schedule">
                        {schedule.map((entry, index) => (
                          <div key={index} style={{ marginBottom: "20px" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "20px",
                              }}
                            >
                              <Form.Item
                                label="Event Name or Description"
                                style={{ width: "100%" }}
                              >
                                <Input
                                  value={entry.text}
                                  onChange={(e) =>
                                    handleScheduleTextChange(e, index)
                                  }
                                  placeholder="Text"
                                />
                              </Form.Item>
                              <Form.Item
                                label="Select Date"
                                style={{ width: "100%" }}
                              >
                                <DatePicker
                                  style={{ width: "100%" }}
                                  value={entry.date}
                                  onChange={(date) =>
                                    handleScheduleDateChange(date, index)
                                  }
                                />
                              </Form.Item>
                              <Form.Item
                                label="Select Time"
                                style={{ width: "100%" }}
                              >
                                <TimePicker
                                  style={{ width: "100%" }}
                                  value={entry.time}
                                  onChange={(time) =>
                                    handleScheduleTimeChange(time, index)
                                  }
                                />
                              </Form.Item>
                              <Button
                                onClick={() => handleRemoveSchedule(index)}
                              >
                                Remove
                              </Button>
                            </div>
                          </div>
                        ))}
                        <Button onClick={handleAddSchedule} type="default">
                          Add Schedule Field
                        </Button>
                      </Form.Item> */}
                    </div>
                    {/* div 1 end here */}

                    {/* div 2 here  */}

                    <div style={{ width: "100%" }}>
                      <div
                        style={{ backgroundColor: "#f9f9f9", padding: "20px" }}
                      >
                        <div>
                          <h2>Tournament Media</h2>
                        </div>
                        {/* <Form.Item
                          label="Upload Banner: Image should not be bigger than 5MB"
                          name="banner"
                          style={{ width: "100%" }}
                          required
                          rules={[
                            {
                              required: true,
                              message: "Please input the contact number",
                            },
                          ]}
                        >
                          <Upload
                            accept=".png,.jpeg,.jpg"
                            beforeUpload={handleBannerImageUpload}
                            fileList={bannerImage ? [bannerImage] : []}
                            onRemove={() => setBannerImage(null)}
                          >
                            <Button icon={<UploadOutlined />} size="small">
                              Select File
                            </Button>
                          </Upload>
                          {!imageSizeValid && bannerImage && (
                            <p style={{ color: "red" }}>
                              Image size exceeds the allowed limit (
                              {MAX_FILE_SIZE_MB} MB).
                            </p>
                          )}
                          {bannerImagePreview && (
                            <div>
                              <img
                                src={bannerImagePreview}
                                alt="Banner Preview"
                                style={{ width: "100%", marginTop: "10px" }}
                              />
                            </div>
                          )}
                        </Form.Item> */}
                        <Form.Item
                          label="Brochure"
                          name="brochure"
                          style={{ width: "100%" }}
                          required
                          rules={[
                            {
                              required: true,
                              message: "Please input the contact number",
                            },
                          ]}
                        >
                          <Upload
                            accept=".pdf"
                            beforeUpload={handleBrochureUpload}
                            fileList={brochure ? [brochure] : []}
                            onRemove={() => setBrochure(null)}
                          >
                            <Button icon={<UploadOutlined />} size="small">
                              Select File
                            </Button>
                          </Upload>
                          {!imageSizeValid && brochure && (
                            <p style={{ color: "red" }}>
                              Image size exceeds the allowed limit (
                              {MAX_FILE_SIZE_MB} MB).
                            </p>
                          )}
                          {brochurePreview && (
                            <div>
                              <a
                                href={brochurePreview}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Preview Brochure
                              </a>
                            </div>
                          )}
                        </Form.Item>
                      </div>
                    </div>
                    {/* div 2 end here  */}
                  </div>

                  <div style={{ marginTop: "40px" }}>
                    <Button
                      onClick={(e) => handleSubmit(e, draftMode)}
                      size="large"
                      type="primary"
                      loading={loading}
                    >
                      Save {draftMode ? "Draft" : "Tournament"}
                    </Button>

                    {/* <Button
                      onClick={handleSaveDraft}
                      size="large"
                      type="default"
                      style={{ marginLeft: 10 }}
                      loading={loading}
                    >
                      Save as Draft
                    </Button> */}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default CreateTournament;
