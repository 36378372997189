import React, { useEffect, useState, useContext } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import MainNavigation from "../../shared/menu/MainNavigation";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { Document, Page } from "react-pdf";
import { AuthContext } from "../../shared/context/auth-context";
import { useSuccess } from "../../shared/context/success-context";
import { Helmet } from "react-helmet";
import moment from "moment";

import { Layout, Spin, Button, Row, Col, Modal } from "antd";
const { Content, Sider } = Layout;

function TournamentDetails() {
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const { successMessage, clearSuccess } = useSuccess();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [tournamentDetails, setTournamentDetails] = useState();
  const location = useLocation();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(location.pathname);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const userId = useParams().id;

  useEffect(() => {
    const fetchTournamentDetails = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}tournaments/details/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        setLoading(false);
        setTournamentDetails(response.data.tournament);

        const queryParams = new URLSearchParams(location.search);
        const successParam = queryParams.get("success");

        if (successParam) {
          setUpdateSuccess(true);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchTournamentDetails();
  }, [userId, auth.token, location.search]);

  const handleModalClose = () => {
    setModalVisible(false);
  };

  // const renderSchedule = (tournamentDetails) => {
  //   if (
  //     !tournamentDetails ||
  //     !tournamentDetails.schedule ||
  //     tournamentDetails.schedule.length === 0
  //   ) {
  //     return <p>No schedule available</p>;
  //   }

  // return (
  //   <div>
  //     <h3>Schedule</h3>
  //     {tournamentDetails.schedule.map((schedule, index) => (
  //       <div key={index}>
  //         <p>Event Name: {schedule.text}</p>
  //         <p>Date: {moment(schedule.date).format("D MMMM, YYYY")}</p>
  //         <p>Time: {moment(schedule.time).format("HH:mm A")}</p>
  //       </div>
  //     ))}
  //   </div>
  // );
  // };

  const renderFile = () => {
    return (
      <>
        {/* <img
          src={`https://billsubmit.sgp1.cdn.digitaloceanspaces.com/Chess7/banners/${tournamentDetails?.bannerImage}`}
          alt={tournamentDetails?.name}
          style={{ width: "100%" }}
        /> */}
        <embed
          src={`${tournamentDetails?.brochureURL}`}
          type="application/pdf"
          style={{ width: "100%", height: "100vh" }}
        />
      </>
    );
  };

  return (
    <Layout hasSider style={{ minHeight: "100vh" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tournament Details | Chess7</title>
        <link
          rel="canonical"
          href={`https://api.chess7.com/api/tournaments/details/${userId}`}
        />
      </Helmet>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      {loading ? <Spin /> : null}

      <Layout className="site-layout" style={{ marginLeft: 200 }}>
        <Content style={{ padding: "24px", minHeight: "100vh" }}>
          <div style={{ padding: 24, background: "white" }}>
            {updateSuccess && (
              <div className="success-message">
                Tournament updated successfully!
              </div>
            )}
            <Modal
              title="Success"
              modalVisible={modalVisible}
              onCancel={() => {
                handleModalClose();
                clearSuccess();
              }}
              onOk={() => {
                handleModalClose();
                clearSuccess();
              }}
            >
              <p>{successMessage}</p>
            </Modal>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ flex: 1, marginRight: 20 }}>
                <Row>
                  <h2
                    style={{
                      padding: 20,
                      fontSize: "35px",
                    }}
                  >
                    {tournamentDetails?.name}
                  </h2>
                </Row>
                {/* Details Rows */}
                <Row style={{ padding: 20 }}>
                  <Col span={8}>
                    <p style={{ fontWeight: "bold" }}>Start Date</p>
                    <p>
                      {moment(tournamentDetails?.startDate).format(
                        "D MMMM, YYYY"
                      )}
                    </p>
                  </Col>
                  <Col span={8}>
                    <p style={{ fontWeight: "bold" }}>End Date</p>
                    <p>
                      {moment(tournamentDetails?.endDate).format(
                        "D MMMM, YYYY"
                      )}
                    </p>
                  </Col>
                  <Col span={8}>
                    <p style={{ fontWeight: "bold" }}>Venue</p>
                    <p>
                      {tournamentDetails?.address}, {tournamentDetails?.city},{" "}
                      {tournamentDetails?.state}
                    </p>
                  </Col>
                </Row>
                <Row style={{ padding: 20 }}>
                  <Col span={8}>
                    <p style={{ fontWeight: "bold" }}>Organized By</p>
                    <p>{tournamentDetails?.organization}</p>
                  </Col>
                  <Col span={8}>
                    <p style={{ fontWeight: "bold" }}>Event Code</p>
                    <p>{tournamentDetails?.eventCode}</p>
                  </Col>
                  <Col span={8}>
                    <p style={{ fontWeight: "bold" }}>Prize Money</p>
                    <p>{tournamentDetails?.totalPrize}</p>
                  </Col>
                </Row>
                <Row style={{ padding: 20 }}>
                  <Col span={8}>
                    <p style={{ fontWeight: "bold" }}>Contact Person</p>
                    <p>{tournamentDetails?.contactPerson?.[0]?.name}</p>
                  </Col>
                  <Col span={8}>
                    <p style={{ fontWeight: "bold" }}>Contact No.</p>
                    <p>{tournamentDetails?.contactPerson?.[0]?.number}</p>
                  </Col>
                  <Col span={8}>
                    <p style={{ fontWeight: "bold" }}>Designation</p>
                    <p>{tournamentDetails?.contactPerson?.[0]?.position}</p>
                  </Col>
                </Row>
                {/* <Row style={{ padding: 20 }}> */}
                {/* <Col>
                    <p style={{ fontWeight: "bold" }}>Tournament Rules</p>
                    <p>{tournamentDetails?.tournamentRules}</p>
                  </Col> */}
                {/* </Row> */}
                {/* <div>
                  <h3 style={{ paddingLeft: 20 }}> Prizes </h3>
                  <div style={{}}>
                    <Row gutter={[16, 16]} style={{ paddingLeft: 20 }}>
                      {tournamentDetails?.prizes?.map((category, index) => (
                        <Col
                          key={index}
                          span={8}
                          style={{ fontWeight: "bold" }}
                        >
                          <h3>{category.category}</h3>
                          {category.prizes.map((prize, innerIndex) => (
                            <div key={innerIndex}>
                              <p>Position: {prize.position}</p>
                              <p>Prize: {prize.prize}</p>
                            </div>
                          ))}
                        </Col>
                      ))}
                    </Row>
                  </div>
                </div> */}
                <div>
                  <Row gutter={[16, 16]} style={{ paddingLeft: 20 }}>
                    <Col span={8} style={{ fontWeight: "bold" }}>
                      <h3>Entry Fees</h3>
                      {tournamentDetails?.entryFees?.map((entry, index) => (
                        <div key={index}>
                          <p>Category: {entry.category}</p>
                          <p>Fees: {entry.fee}</p>
                        </div>
                      ))}
                    </Col>
                  </Row>
                </div>
                {/* <div>{renderSchedule(tournamentDetails)}</div> */}
              </div>
              {/* Image Div Starts */}
              <div style={{ flex: 1 }}>
                {renderFile()}
                <h1 style={{ color: "#333", textAlign: "center" }}>
                  {tournamentDetails?.name}
                </h1>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default TournamentDetails;
